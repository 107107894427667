import { Suspense, useCallback, useEffect, useRef, useState } from 'react';
import {
    useCreateOrGetTaxExemptionPortalLink,
    useCreateOrUpdateBusinessAddress,
} from '~/adapters/services/accounts/taxExemptionsECM/hooks';
import { BusinessAddressWName } from '~/adapters/services/accounts/taxExemptionsECM/types';
import { useForm } from 'react-hook-form';
import {
    Alert,
    Box,
    Button,
    Dialog,
    DialogCommonActions,
    DialogContent,
    Grid,
    Tooltip,
    Typography,
    useMobileBreakpoint,
    TextField,
} from '@customink/pigment-react';
import Spinner from '~/components/Spinner';
import designTokens from '@customink/design-tokens/build/ci_light/js/designTokens';
import {
    avalaraTakingTooLongMessage,
    ButtonVariantText,
    OptionalCustomer,
} from '~/pages/Settings/tabs/TaxExemptionECM/components/Portal/common';
import { Loader } from '~/components/Loader/loader';
import { RedirectingTaxExemptionPath } from '~/config';
import { log } from '~/adapters/browser/localLogger';
import { trackEvent } from '~/adapters/tracking/tracking';
import SelectState from '~/pages/Settings/tabs/TaxExemptionECM/components/Portal/SelectState';
import { ChatWithUs } from '~/pages/Settings/tabs/TaxExemptionECM/components/Portal/ChatWithUs';
import { useRegisterInput } from '~/adapters/react-hook-form/registerInput';

/* eslint-disable react/jsx-props-no-spreading */

type AddBusinessAddressButtonProps = ButtonVariantText & {
    customer: OptionalCustomer;
    onCustomerUpdate: () => void;
    onClick?: () => void;
};

const requiredErrorMessage = 'Please fill out this field.';

function AddBusinessAddressButton({
    text,
    variant,
    customer,
    onCustomerUpdate,
    onClick,
}: AddBusinessAddressButtonProps) {
    const [open, setOpen] = useState(false);
    const alertErrorRef = useRef<HTMLDivElement | null>(null);
    const [isCustomerReadyInternal, setIsCustomerReadyInternal] = useState(false);

    const {
        mutateAsync: createOrUpdateCustomer,
        isPending: isCustomerPending,
        hasError,
    } = useCreateOrUpdateBusinessAddress();
    const {
        isPending: isPortalPending,
        data: portalLink,
        refetch: createOrGetPortalLink,
    } = useCreateOrGetTaxExemptionPortalLink({
        enabled: false,
    });
    const portalLinkNotReady = portalLink?.status === 'InProgress';
    const isPending = isCustomerPending || (isCustomerReadyInternal && isPortalPending);

    useEffect(() => {
        if (hasError) {
            trackEvent({
                category: 'Tax Exempt Account Modal',
                action: 'Error',
                label: 'Failed Verification',
            });
            alertErrorRef.current?.scrollIntoView({ behavior: 'smooth' });
        }
    }, [hasError]);

    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
    } = useForm<BusinessAddressWName>({ defaultValues: { organizationName: customer?.name } });
    const registerInput = useRegisterInput(register);
    const registerMuiComp = useCallback(
        (name: keyof BusinessAddressWName, required?: boolean, otherOptions = {}) => ({
            error: !!errors[name],
            helperText: errors[name]?.message,
            InputLabelProps: { required },
            fullWidth: true,
            removeHelperTextSpace: !errors[name],
            ...registerInput(name, {
                required: required ? requiredErrorMessage : false,
                ...otherOptions,
            }),
        }),
        [register, errors],
    );

    const handleSave = useCallback(
        async (data: BusinessAddressWName) => {
            trackEvent({
                category: 'Tax Exempt Account Modal',
                action: 'Click',
                label: 'Continue',
            });
            await createOrUpdateCustomer({ ...data, country: 'US' });
            setIsCustomerReadyInternal(true);
            const tePortalWindow = window.open(RedirectingTaxExemptionPath, '_blank');
            const portalLinkPromised = await createOrGetPortalLink();
            if (portalLinkPromised.data?.status === 'Ready') {
                if (tePortalWindow) {
                    tePortalWindow.location.href = portalLinkPromised.data.invitation.requestLink;
                } else {
                    log.warn('Browser blocked opening a new tab. Redirecting in this tab as a fallback.');
                    window.location.href = portalLinkPromised.data.invitation.requestLink;
                }
                // Intentionally invalidating the customer only now when portalLinkQuery is finished. Customer having the business address will unmount this component.
                onCustomerUpdate();
            } else {
                tePortalWindow?.close();
                // Case when `status !== 'Ready'` handled declaratively below
            }
        },
        [createOrUpdateCustomer, setIsCustomerReadyInternal],
    );

    const isMobile = useMobileBreakpoint();

    const handleClickOpen = useCallback(() => {
        setOpen(true);
        onClick?.();
    }, []);

    const handleClickClose = useCallback(() => {
        setOpen(false);
        trackEvent({
            category: 'Tax Exempt Account Modal',
            action: 'Click',
            label: 'Close',
        });
    }, []);

    return (
        <>
            <Button onClick={handleClickOpen} variant={variant} data-testid="verify tax exempt status">
                {text}
            </Button>
            <Dialog
                open={open}
                onClose={handleClickClose}
                title="Verify Tax Exempt Status"
                width="lg"
                disableRestoreFocus
            >
                <DialogContent>
                    {hasError && (
                        <Alert severity="error" sx={{ mb: 1 }} ref={alertErrorRef}>
                            <Typography variant="paragraphSmall">
                                We were unable to save your tax exemption information. Please try again later.
                            </Typography>
                        </Alert>
                    )}
                    {isCustomerReadyInternal && portalLinkNotReady && (
                        <Alert severity="info" sx={{ mb: 1 }}>
                            <Typography variant="paragraphSmall">{avalaraTakingTooLongMessage}</Typography>
                        </Alert>
                    )}
                    <form id="tax-exemptions-form" onSubmit={handleSubmit(handleSave)}>
                        <Box>
                            <Typography
                                sx={{ p: '14px 16px', mb: 3, background: designTokens.color.gray.light.value }}
                                paragraph
                                variant="paragraphDefault"
                            >
                                You might notice things look a bit different - we&apos;ve upgraded our tax exemption
                                experience. Any existing certificates have been securely transferred and remain valid.
                            </Typography>
                        </Box>
                        <Typography paragraph variant="paragraphDefault">
                            To proceed with tax exemption verification, please provide your organization&apos;s details.
                        </Typography>
                        <Typography mb={3} paragraph variant="paragraphDefault">
                            After submission, you will be redirected to our tax exemption portal to complete the
                            process. Verification is instant in most cases, but for some states, it may take up to 3
                            business days.
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item sm={12}>
                                <TextField
                                    label="Organization (e.g. CalTech, Sharks Soccer)"
                                    placeholder="CalTech"
                                    autoFocus
                                    inputProps={{ autoComplete: 'organization' }}
                                    {...registerMuiComp('organizationName', true)}
                                />
                            </Grid>
                            <Grid item sm={12}>
                                <TextField
                                    label="Street Address"
                                    placeholder="123 Pine St"
                                    inputProps={{ autoComplete: 'address-line1' }}
                                    {...registerMuiComp('line1', true)}
                                />
                            </Grid>
                            <Grid item sm={12}>
                                <TextField
                                    label="Address Line 2 (Optional)"
                                    placeholder="Apt / Suite / Floor"
                                    inputProps={{ autoComplete: 'address-line2' }}
                                    {...registerMuiComp('line2')}
                                />
                            </Grid>
                            <Grid item sm={12} md={4}>
                                <TextField
                                    label="City"
                                    placeholder="City"
                                    inputProps={{ autoComplete: 'address-level2' }}
                                    {...registerMuiComp('city', true)}
                                />
                            </Grid>
                            <Grid item sm={12} md={4}>
                                <Suspense fallback={<Loader title="State" />}>
                                    <SelectState control={control} />
                                </Suspense>
                            </Grid>
                            <Grid item sm={12} md={4}>
                                <TextField
                                    label="ZIP Code"
                                    placeholder="12345"
                                    inputProps={{ autoComplete: 'postal-code' }}
                                    {...registerMuiComp('postalCode', true, { maxLength: 5, minLength: 5 })}
                                />
                            </Grid>
                        </Grid>
                    </form>
                </DialogContent>
                <DialogCommonActions>
                    <Tooltip title={portalLinkNotReady && avalaraTakingTooLongMessage}>
                        <Button
                            fullWidth={isMobile}
                            type="submit"
                            variant="primary"
                            disabled={isPending || portalLinkNotReady}
                            form="tax-exemptions-form"
                            data-testid="add bussiness address button"
                        >
                            {isPending ? <Spinner /> : 'Continue'}
                        </Button>
                    </Tooltip>
                    <Button variant="secondary" onClick={handleClickClose} fullWidth={isMobile}>
                        Cancel
                    </Button>
                    <ChatWithUs />
                </DialogCommonActions>
            </Dialog>
        </>
    );
}

export default AddBusinessAddressButton;
