import { OrderDetails, OrderSummary } from '~/adapters/services/accounts/orders/types';

export const transformOrderDetailsToOrderSummary = (orderDetails: OrderDetails): OrderSummary => {
    const quantity = orderDetails.orderItems.reduce((quantityAcc, orderItem) => {
        return (
            quantityAcc +
            orderItem.orderItemQuantities.reduce((itemQuantitiesAcc, orderItemQuantity) => {
                return itemQuantitiesAcc + orderItemQuantity.quantity;
            }, 0)
        );
    }, 0);
    const designNames = orderDetails.orderItems.map((orderItem) => orderItem.designName).filter(Boolean);
    return {
        id: orderDetails.id,
        proofs: orderDetails.artProofs.map((artProof) => artProof.proofUrl),
        deliveredAt: orderDetails.schedule.deliveredAt,
        state: orderDetails.state,
        cancelled: orderDetails.cancelled,
        accountId: orderDetails.accountId,
        designName: designNames[0] ?? '',
        designId: orderDetails.orderItems[0].designId,
        hasPersonalization: orderDetails.hasPersonalization,
        placedAt: orderDetails.schedule.placedAt,
        quantity,
        shipper: orderDetails.shippingDetail.shipper,
        totalPrice: orderDetails.payment.totalPrice,
        trackingNumber: orderDetails.shippingDetail.trackingNumber,
    };
};
