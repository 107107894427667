import { Stack } from '@customink/pigment-react';
import { styled } from '@customink/pigment-react/lib/themeCustomink';
import Spinner from '../Spinner';

interface LoaderProps {
    title: string;
    devLabel?: string;
}

const LoadingOverlay = styled(Stack)(({ theme }) => ({
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '5px',
    lineHeight: '2.5rem',
    textAlign: 'center',
    fontWeight: 'bold',
    color: theme.palette.grey[400],
}));

const isDevelopmentMode = import.meta.env.MODE === 'development';

export function Loader({ title, devLabel }: LoaderProps) {
    let message = title;
    if (isDevelopmentMode && devLabel) {
        message = `${message} ${devLabel}`;
    }

    return (
        <LoadingOverlay>
            <Spinner /> {message}
        </LoadingOverlay>
    );
}
