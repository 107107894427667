import { MouseEvent, useCallback, useState } from 'react';
import { Box } from '@customink/pigment-react';
import { Collapse, Divider } from '@mui/material';
import { trackEvent } from '~/adapters/tracking/tracking';
import { Loader } from '~/components/Loader/loader';
import { OrderSummary } from '~/adapters/services/accounts/orders/types';
import { useOrderDetails } from '~/adapters/services/accounts/orders/hooks';
import { OrderDetail } from './OrderDetail/OrderDetail';
import { OrderHeaderRow } from './OrderHeaderRow/OrderHeaderRow';
import { OrderContentRow } from './OrderContentRow/OrderContentRow';

interface Props {
    order: OrderSummary;
    singleDetail: boolean;
}

export function OrderHistoryCard({ order, singleDetail }: Props) {
    const [open, setOpen] = useState(false);
    const { data, isLoading } = useOrderDetails({ orderId: order.id }, { enabled: open });

    const toggleDetail = useCallback(
        (e: MouseEvent<HTMLElement>): void => {
            e.preventDefault();
            if (!open) {
                trackEvent({ action: 'accounts order', label: 'details' });
            }
            setOpen(!open);
        },
        [open],
    );

    return (
        <Box sx={(theme) => ({ border: `1px solid ${theme.palette.divider}`, width: '100%' })}>
            <OrderHeaderRow order={order} />
            <Divider />
            <OrderContentRow order={order} detailOpen={open} toggle={toggleDetail} singleDetail={singleDetail} />
            <Collapse in={open && !singleDetail} unmountOnExit>
                {isLoading && <Loader title="Loading..." />}
                {data && <OrderDetail orderDetails={data} />}
            </Collapse>
        </Box>
    );
}
