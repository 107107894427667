import { PortalButton } from '~/pages/Settings/tabs/TaxExemptionECM/components/Portal/PortalButton';
import { ButtonVariantText, OptionalCustomer } from '~/pages/Settings/tabs/TaxExemptionECM/components/Portal/common';
import AddBusinessAddressButton from '~/pages/Settings/tabs/TaxExemptionECM/components/Portal/AddBusinessAddressButton';

type PortalOrBusinessAddressButtonProps = ButtonVariantText & {
    customer: OptionalCustomer;
    onCustomerUpdate: () => void;
    /* Called in addition to any internal functionality. */
    onClick?: () => void;
};

/**
 * A button that either leads to the AvaTax portal or prompts the user to add a business address, and then opens the portal.
 * The button appears the same for both cases.
 */
export function PortalOrBusinessAddressButton({
    text,
    customer,
    variant,
    onCustomerUpdate,
    onClick,
}: PortalOrBusinessAddressButtonProps) {
    if (customer === null || customer.businessAddress === null) {
        return (
            <AddBusinessAddressButton
                text={text}
                variant={variant}
                customer={customer}
                onCustomerUpdate={onCustomerUpdate}
                onClick={onClick}
                data-testid="verify tax exempt status"
            />
        );
    }
    return (
        <PortalButton
            text={text}
            variant={variant}
            customer={{ ...customer, businessAddress: customer.businessAddress }}
            data-testid="portal button"
            onClick={onClick}
        />
    );
}
